import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { FontAwesomeIcon as F } from '@fortawesome/react-fontawesome';
import { faCaretLeft as left, faCaretRight as right } from '@fortawesome/free-solid-svg-icons';
import c from 'classnames';
import { provideData } from '../data';
import MDXPage, { Consumer } from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import FilterPhrase from '../components/FilterPhrase';
import Table from '../components/Table';
import Link from '../components/Link';
import Header from '../components/Header.mdx';
import StaticNavbar from '../components/StaticNavbar.mdx';
import styles from './index.module.css';
export const getSingleId = (slug, {
  tribesBySlug
}) => tribesBySlug[slug];
export const getSingleData = (tribeId, {
  tribeAllFieldsById
}) => ({
  tribeId,
  ...tribeAllFieldsById[tribeId]
});
export const remainMountedChildren = <>
    <Header mdxType="Header" />
    <StaticNavbar mdxType="StaticNavbar" />
    <Consumer render={({
    name
  }) => <FloatingNavbar anchorLinks={[{
    href: '#about',
    label: name
  }, {
    href: '#map',
    label: 'Map'
  }, {
    href: '#treaties',
    label: 'Treaties'
  }, {
    href: '#university-recipients',
    label: 'University Recipients'
  }, {
    href: '#land-cessions',
    label: 'Land Cessions'
  }]} mdxType="FloatingNavbar" />} mdxType="Consumer" />
    <a name="about" />
    <Section containerWidth className="mb-3" colProps={{
    className: styles.titleBar
  }} mdxType="Section">
      <Consumer render={({
      prevSlug,
      prevName
    }) => <Link className={c('d-none d-desktop-flex', styles.prevLink)} href={`/tribes/${prevSlug}`} mdxType="Link">
            <F icon={left} mdxType="F" />
            {prevName}
          </Link>} mdxType="Consumer" />
      <Consumer render={({
      name
    }) => <div className={styles.title}>
            <h2>{name}</h2>
          </div>} mdxType="Consumer" />
      <Consumer render={({
      nextSlug,
      nextName
    }) => <Link className={c('d-none d-desktop-flex', styles.nextLink)} href={`/tribes/${nextSlug}`} mdxType="Link">
            {nextName}
            <F icon={right} mdxType="F" />
          </Link>} mdxType="Consumer" />
    </Section>
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4>
          <Consumer render={({
      name
    }) => name} mdxType="Consumer" />
          {' Land Granted to Universities'}
        </h4>} mapSubtitle="Morrill Act parcels, treaty areas and recipient schools" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  getSingleId,
  getSingleData,
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = provideData(MDXPage, ['tribesBySlug', 'tribeAllFieldsById']);
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <Consumer render={({
      name
    }) => <PageMeta title={name} mdxType="PageMeta" />} mdxType="Consumer" />
    <a name="treaties" />
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <FilterPhrase name="tribe-signatory-to-transfers-for-parcels" resetButton={false} tribe={tribeId} mdxType="FilterPhrase" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <Table name="transfers-for-tribe" tribe={tribeId} pageSize={10} mdxType="Table" />} mdxType="Consumer" />
    </Section>
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <FilterPhrase name="universities-earned-from-tribe-parcels-in-state" tribe={tribeId} mdxType="FilterPhrase" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <Table name="universities-for-selected-states-for-tribe" tribe={tribeId} pageSize={10} mdxType="Table" />} mdxType="Consumer" />
    </Section>
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <FilterPhrase name="parcels-in-state-ceded-by-tribe" tribe={tribeId} mdxType="FilterPhrase" />} mdxType="Consumer" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Consumer render={({
        tribeId
      }) => <Table name="lands-for-selected-states-for-tribe" tribe={tribeId} pageSize={10} mdxType="Table" />} mdxType="Consumer" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      